<template>
  <v-container id="dashboard" fluid tag="section">
    <!-- top row (line 1) -->
    <v-row>
    <v-col cols="12" sm="" lg="2" class="mx-auto">
    </v-col>
      <!-- RFP Counts Pie Chart -->
      <v-col cols="12" sm="5" lg="8" class="mx-auto">
        <base-material-chart-card
          :testTotal="total"
          :data="rfpCountChart.data"
          :options="rfpCountChart.options"
          :responsive-options="rfpCountChart.responsiveOptions"
          color="secondary"
          hover-reveal
          type="Pie"
        >
          <template v-slot:reveal-actions>
            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn v-bind="attrs" color="info" icon v-on="on">
                  <v-icon color="info">mdi-refresh</v-icon>
                </v-btn>
              </template>

              <span>Refresh</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn v-bind="attrs" light icon v-on="on">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>

              <span>Change Date</span>
            </v-tooltip>
          </template>

          <h4 class="card-title font-weight-light mt-2 ml-2">
            RFP Count:
            <b class="font-weight-bold display-1">{{ total }}</b>
          </h4>

          <p class="d-inline-flex font-weight-light ml-2 mt-1">
            Total RFP Performance
          </p>
          <v-row
            class="d-flex flex-column flex-sm-row align-start align-sm-center justify-space-around mx-0"
          >
            <span
              class="d-flex flex-row align-center justify-center font-weight-light ml-2 subtitle-2 mb-2"
            >
              <v-icon class="mr-4" color="#35a7ff" small>mdi-circle</v-icon>Open
              RFPs:
              <b class="font-weight-bold ml-1">{{ openRFPCount }}</b>
            </span>
            <span
              class="d-flex flex-row align-center justify-center font-weight-light ml-2 subtitle-2 mb-2"
            >
              <v-icon class="mr-4" color="#00448f" small>mdi-circle</v-icon
              >Completed RFPs:
              <b class="font-weight-bold ml-1">{{ completedRFPCount }}</b>
            </span>
          </v-row>
          <template v-slot:actions>
            <v-icon class="mr-1" small>mdi-clock-outline</v-icon>
            <span class="caption grey--text font-weight-light"
            >just updated</span
            >
          </template>
        </base-material-chart-card>
      </v-col>

      <v-col cols="12"  sm=""  lg="2" class="mx-auto">
        <v-card-title class="custom-title-style  mb-4 ml-0">
          Filter By Date Range
        </v-card-title>
        <v-row  no-gutters class="mt-0 justify-center" >
               <v-col cols="12" sm="" lg="12">
              <v-menu
                v-model="startDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="startDate"
                    label="Start Date"
                    readonly
                    outlined
                    dense
                    append-icon="mdi-calendar"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker  class="datepicker" v-model="startDate" title scrollable @input="updateEndDate"   @change="handleDateChange" :max="today" no-title></v-date-picker>
              </v-menu>
              </v-col>
          </v-row>
          <v-row no-gutters class="mt-0">
              <v-col cols="12" sm="" lg="12">
              <v-menu
                v-model="endDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="endDate"
                    label="End Date"
                    readonly
                    append-icon="mdi-calendar"
                    outlined
                    dense
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker class="datepicker" v-model="endDate" title scrollable :min="minEndDate"  @change="handleDateChange" :max="today" no-title></v-date-picker>
              </v-menu>
          </v-col>
          </v-row>
          <v-row no-gutters class="mt-0 justify-center">
            <v-col cols="12" md="6"  class="mr-4">
            <v-btn @click="fetchMostCompareCityData" color="primary" style="width: 120%;">Filter</v-btn>
        
            <v-btn @click="resetDates" :disabled="isResetDisabled"  class="mt-2" style="width: 120%;" color="primary">Reset</v-btn>
          </v-col>
        </v-row>
       </v-col>


    <!-- Start Date Picker -->
   
   
    </v-row>
    <!-- mid row (line 2) -->
    <v-row>
      <!-- total organiser count -->
      <v-col cols="12" sm="6" lg="4">
        <base-material-stats-card
          color="info"
          icon="user-tie"
          title="CO Subscribers"
          :value="organiserCount"
          sub-icon="mdi-clock-outline"
          sub-text="just updated"
        />
      </v-col>

      <!-- total venues count -->
      <v-col cols="12" sm="6" lg="4">
        <base-material-stats-card
          color="primary"
          icon="hotel"
          title="Venue Subscribers"
          :value="venueCount"
          sub-icon="mdi-clock-outline"
          sub-text="just updated"
        />
      </v-col>

      <!-- total city sub count -->
      <v-col cols="12" sm="6" lg="4">
        <base-material-stats-card
          color="secondary"
          icon="map-marked-alt"
          title="City Subscribers"
          :value="citySubscriberCount"
          sub-icon="mdi-clock-outline"
          sub-text="just updated"
        />
      </v-col>

      <!-- total city sub count - advanced -->
      <v-col cols="12" sm="6" lg="4">
        <base-material-stats-card
          color="primary"
          icon="map-marked-alt"
          title="Advanced City Subscribers"
          :value="advancedCitySubscriberCount"
          sub-icon="mdi-clock-outline"
          sub-text="just updated"
        />
      </v-col>

      <!-- total city sub count - basic -->
      <v-col cols="12" sm="6" lg="4">
        <base-material-stats-card
          color="secondary"
          icon="map-marked-alt"
          title="Basic City Subscribers"
          :value="basicCitySubscriberCount"
          sub-icon="mdi-clock-outline"
          sub-text="just updated"
        />
      </v-col>

      <!-- total city count -->
      <v-col cols="12" sm="6" lg="4">
        <base-material-stats-card
          color="primary"
          icon="city"
          title="Cities"
          :value="cityCount"
          sub-icon="mdi-clock-outline"
          sub-text="just updated"
        />
      </v-col>
    </v-row>

    <!-- bottom row (line 3) -->
    <v-row>
      <!-- top 5 cities (by CTR) -->
      <v-col cols="12" md="4">
        <base-material-card color="primary" class="px-5 py-3">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">Top 5 Cities (CTR)</div>
            <div class="subtitle-1 font-weight-light">
              The cities which have the highest engagement/CTR rate
            </div>
          </template>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th
                    class="text-left"
                    v-for="(header, index) in mostClickCityHeaders"
                    :key="index"
                  >
                    {{ header.text }}
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in mostClickCityItems" :key="item.name">
                  <td>{{ item.position }}</td>
                  <td>{{ item.city }}</td>
                  <td>{{ item.country }}</td>
                  <td>{{ item.clickCount }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
          <template v-slot:actions>
            <v-btn
              color="primary"
              class="ml-auto px-5"
              @click="exportMostClickedFn"
            >
              <v-row
                class="d-flex flex-row align-center justify-center"
                no-gutters
              >
                <v-icon>mdi-export-variant</v-icon>
                <span class="text-left ml-2">Export CSV</span>
              </v-row>
            </v-btn>
          </template>
        </base-material-card>
      </v-col>

      <!-- top 5 shortlisted cities -->
      <v-col cols="12" md="4">
        <base-material-card color="primary" class="px-5 py-3">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Top 5 Shortlisted Cities
            </div>
            <div class="subtitle-1 font-weight-light">
              The cities which have the highest shortlist count
            </div>
          </template>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th
                    class="text-left"
                    v-for="(header, index) in mostCompareCityHeader"
                    :key="index"
                  >
                    {{ header.text }}
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in mostCompareCityItems" :key="item.name">
                  <td>{{ item.position }}</td>
                  <td>{{ item.cityInfo.city }}</td>
                  <td>{{ item.cityInfo.country }}</td>
                  <td>{{ item.total }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
          <template v-slot:actions>
            <v-btn
              color="primary"
              class="ml-auto px-5"
              @click="exportMostComparedFn"
            >
              <v-row
                class="d-flex flex-row align-center justify-center"
                no-gutters
              >
                <v-icon>mdi-export-variant</v-icon>
                <span class="text-left ml-2">Export CSV</span>
              </v-row>
            </v-btn>
          </template>
        </base-material-card>
      </v-col>

      <v-col cols="12" md="4">
        <base-material-card color="primary" class="px-5 py-3">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">Top Search Appearance for Cities</div>
            <div class="subtitle-1 font-weight-light">
              The cities which have the highest search appearance
            </div>
          </template>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th
                    class="text-left"
                    v-for="(header, index) in mostSearchedHeaders"
                    :key="index"
                  >
                    {{ header.text }}
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in mostSearchCityItems" :key="item.name">
                  <td>{{ item.position }}</td>
                  <td>{{ item.city }}</td>
                  <td>{{ item.country }}</td>
                  <td>{{ item.searchCount }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
          <template v-slot:actions>
            <v-btn
              color="primary"
              class="ml-auto px-5"
              @click="exportMostSearchedFn"
            >
              <v-row
                class="d-flex flex-row align-center justify-center"
                no-gutters
              >
                <v-icon>mdi-export-variant</v-icon>
                <span class="text-left ml-2">Export CSV</span>
              </v-row>
            </v-btn>
          </template>
        </base-material-card>
      </v-col>

      <!-- top 5 cities (by CTR) -->
      <v-col cols="12" md="4">
        <base-material-card color="primary" class="px-5 py-3">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">Top 5 Countries (CTR)</div>
            <div class="subtitle-1 font-weight-light">
              The countries which have the highest engagement
            </div>
          </template>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th
                    class="text-left"
                    v-for="(header, index) in mostClickCountryHeaders"
                    :key="index"
                  >
                    {{ header.text }}
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in mostClickCountryItems" :key="item.name">
                  <td>{{ item.position }}</td>
                  <td>{{ item.country }}</td>
                  <td>{{ item.ctr }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
          <template v-slot:actions>
            <v-btn
              color="primary"
              class="ml-auto px-5"
              @click="exportMostClickedCountryFn"
            >
              <v-row
                class="d-flex flex-row align-center justify-center"
                no-gutters
              >
                <v-icon>mdi-export-variant</v-icon>
                <span class="text-left ml-2">Export CSV</span>
              </v-row>
            </v-btn>
          </template>
        </base-material-card>
      </v-col>

      <!-- top 5 shortlisted country -->
      <v-col cols="12" md="4">
        <base-material-card color="primary" class="px-5 py-3">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Top 5 Shortlisted Countries
            </div>
            <div class="subtitle-1 font-weight-light">
              The countries which have the highest shortlist count
            </div>
          </template>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th
                    class="text-left"
                    v-for="(header, index) in mostCompareCountryHeader"
                    :key="index"
                  >
                    {{ header.text }}
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in mostCompareCountryItems" :key="item.name">
                  <td>{{ item.position }}</td>
                  <td>{{ item.country }}</td>
                  <td>{{ item.ctr }}</td>
                  <td>{{ item.total }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
          <template v-slot:actions>
            <v-btn
              color="primary"
              class="ml-auto px-5"
              @click="exportMostComparedCountryFn"
            >
              <v-row
                class="d-flex flex-row align-center justify-center"
                no-gutters
              >
                <v-icon>mdi-export-variant</v-icon>
                <span class="text-left ml-2">Export CSV</span>
              </v-row>
            </v-btn>
          </template>
        </base-material-card>
      </v-col>

      <v-col cols="12" md="4">
        <base-material-card color="primary" class="px-5 py-3">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">Top Search Appearance for Countries</div>
            <div class="subtitle-1 font-weight-light">
              The countries which have the highest search appearance
            </div>
          </template>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th
                    class="text-left"
                    v-for="(header, index) in mostSearchedCountryHeaders"
                    :key="index"
                  >
                    {{ header.text }}
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in mostSearchCountryItems" :key="item.name">
                  <td>{{ item.position }}</td>
                  <td>{{ item.country }}</td>
                  <td>{{ item.searchCount }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
          <template v-slot:actions>
            <v-btn
              color="primary"
              class="ml-auto px-5"
              @click="exportMostSearchedCountryFn"
            >
              <v-row
                class="d-flex flex-row align-center justify-center"
                no-gutters
              >
                <v-icon>mdi-export-variant</v-icon>
                <span class="text-left ml-2">Export CSV</span>
              </v-row>
            </v-btn>
          </template>
        </base-material-card>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
import analyticsApis from "../services/analytics.sevice";

export default {
  name: "DashboardDashboard",
  data() {
    return {
      startDateMenu: false,
      endDateMenu: false,
      startDate: null,
      endDate: null,
      organiserCount: 0,
      venueCount: 0,
      citySubscriberCount: 0,
      cityCount: 0,
      completedRFPCount: 0,
      openRFPCount: 0,
      advancedCitySubscriberCount: 0,
      basicCitySubscriberCount: 0,
      total: 0,
      dailySalesChart: {
        data: {
          labels: ["M", "T", "W", "T", "F", "S", "S"],
          series: [[12, 17, 7, 17, 23, 18, 38]]
        },
        options: {
          lineSmooth: this.$chartist.Interpolation.cardinal({
            tension: 0
          }),
          low: 0,
          high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          }
        }
      },
      dataCompletedTasksChart: {
        data: {
          labels: ["12am", "3pm", "6pm", "9pm", "12pm", "3am", "6am", "9am"],
          series: [[230, 750, 450, 300, 280, 240, 200, 190]]
        },
        options: {
          lineSmooth: this.$chartist.Interpolation.cardinal({
            tension: 0
          }),
          low: 0,
          high: 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          }
        }
      },
      emailsSubscriptionChart: {
        data: {
          labels: [
            "Ja",
            "Fe",
            "Ma",
            "Ap",
            "Mai",
            "Ju",
            "Jul",
            "Au",
            "Se",
            "Oc",
            "No",
            "De"
          ],
          series: [[542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895]]
        },
        options: {
          axisX: {
            showGrid: false
          },
          low: 0,
          high: 1000,
          chartPadding: {
            top: 0,
            right: 5,
            bottom: 0,
            left: 0
          }
        },
        responsiveOptions: [
          [
            "screen and (max-width: 640px)",
            {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function (value) {
                  return value[0];
                }
              }
            }
          ]
        ]
      },
      rfpCountChart1: {
        data: {
          labels: ["Open RFPs", "Completed RFPs"],
          series: [
            {
              value: 20,
              name: "Open RFPs",
              className: "open-rfp",
              meta: "Open RFPs"
            },
            {
              value: 10,
              name: "Completed RFPs",
              className: "completed-rfp",
              meta: "Completed RFPs"
            }
          ]
        },
        options: {
          labelInterpolationFnc: function (value) {
            return value;
          },
          donut: true,
          donutWidth: 10,
          donutSolid: true,
          startAngle: 60,
          showLabel: true
        },
        responsiveOptions: [
          [
            "screen and (min-width: 640px)",
            {
              chartPadding: 30,
              labelOffset: 100,
              labelDirection: "explode",
              labelInterpolationFnc: function (value) {
                return value;
              }
            }
          ],
          [
            "screen and (min-width: 1024px)",
            {
              labelOffset: 80,
              chartPadding: 20
            }
          ]
        ]
      },
      mostClickCityHeaders: [
        {
          sortable: false,
          text: "Position",
          value: "position"
        },
        {
          sortable: false,
          text: "City",
          value: "city"
        },
        {
          sortable: false,
          text: "Country",
          value: "country",
          align: "right"
        },
        {
          sortable: false,
          text: "CTR",
          value: "clickCount",
          align: "right"
        }
      ],
      mostClickCountryHeaders: [
        {
          sortable: false,
          text: "Position",
          value: "position"
        },
        {
          sortable: false,
          text: "Country",
          value: "country",
          align: "right"
        },
        {
          sortable: false,
          text: "CTR",
          value: "clickCount",
          align: "right"
        }
      ],
      mostSearchedHeaders: [
        {
          sortable: false,
          text: "Position",
          value: "position"
        },
        {
          sortable: false,
          text: "City",
          value: "city"
        },
        {
          sortable: false,
          text: "Country",
          value: "country",
          align: "right"
        },
        {
          sortable: false,
          text: "Count",
          value: "searchCount",
          align: "right"
        }
      ],
      mostSearchedCountryHeaders: [
        {
          sortable: false,
          text: "Position",
          value: "position"
        },
        {
          sortable: false,
          text: "Country",
          value: "country",
          align: "right"
        },
        {
          sortable: false,
          text: "Count",
          value: "searchCount",
          align: "right"
        }
      ],
      mostCompareCityHeader: [
        {
          sortable: false,
          text: "Position",
          value: "position"
        },
        {
          sortable: false,
          text: "City",
          value: "cityInfo.city"
        },
        {
          sortable: false,
          text: "Country",
          value: "cityInfo.country",
          align: "right"
        },
        {
          sortable: false,
          text: "CTR",
          value: "total",
          align: "right"
        }
      ],
      mostCompareCountryHeader: [
        {
          sortable: false,
          text: "Position",
          value: "position"
        },
        {
          sortable: false,
          text: "Country",
          value: "cityInfo.country",
          align: "right"
        },
        {
          sortable: false,
          text: "CTR",
          value: "total",
          align: "right"
        }
      ],
      headers: [
        {
          sortable: false,
          text: "Position",
          value: "position"
        },
        {
          sortable: false,
          text: "City",
          value: "city"
        },
        {
          sortable: false,
          text: "Country",
          value: "country",
          align: "right"
        },
        {
          sortable: false,
          text: "CTR",
          value: "ctr",
          align: "right"
        }
      ],
      mostCompareCityItems: [],
      mostCompareCountryItems: [],
      mostClickCityItems: [],
      mostClickCountryItems: [],
      mostSearchCityItems: [],
      mostSearchCountryItems: [],
      tabs: 0,
      tasks: {
        0: [
          {
            text:
              'Sign contract for "What are conference organizers afraposition of?"',
            value: true
          },
          {
            text:
              "Lines From Great Russian Literature? Or E-mails From My Boss?",
            value: false
          },
          {
            text:
              "Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit",
            value: false
          },
          {
            text: "Create 4 Invisible User Experiences you Never Knew About",
            value: true
          }
        ],
        1: [
          {
            text:
              "Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit",
            value: true
          },
          {
            text:
              'Sign contract for "What are conference organizers afraid of?"',
            value: false
          }
        ],
        2: [
          {
            text:
              "Lines From Great Russian Literature? Or E-mails From My Boss?",
            value: false
          },
          {
            text:
              "Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit",
            value: true
          },
          {
            text:
              'Sign contract for "What are conference organizers afraid of?"',
            value: true
          }
        ]
      },
      list: {
        0: false,
        1: false,
        2: false
      }
    };
  },
  computed: {
    today() {
    const now = new Date();
    return `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')}`;
  },
    minEndDate() {
      // Compute the minimum selectable end date based on the selected start date
      if (this.startDate) {
        const minEnd = new Date(this.startDate);
        minEnd.setDate(minEnd.getDate() + 1); // Add one day to start date
        return minEnd.toISOString().split('T')[0];
      }
      return null;
    },
    isResetDisabled() {
    return !this.startDate && !this.endDate;
    },
    rfpCountChart() {
      return {
        data: {
          labels: ["Open RFPs", "Completed RFPs"],
          series: [
            {
              value: this.completedRFPCount,
              name: "Open RFPs",
              className: "open-rfp",
              meta: "Open RFPs"
            },
            {
              value: this.openRFPCount,
              name: "Completed RFPs",
              className: "completed-rfp",
              meta: "Completed RFPs"
            }
          ]
        },
        options: {
          labelInterpolationFnc: function (value) {
            return `a ${value}`;
          },
          donut: true,
          donutWidth: 15,
          donutSolid: true,
          startAngle: 90,
          showLabel: true
        },
        responsiveOptions: [
          [
            "screen and (min-width: 640px)",
            {
              chartPadding: 30,
              labelOffset: 100,
              labelDirection: "explode",
              labelInterpolationFnc: function (value) {
                return value;
              }
            }
          ],
          [
            "screen and (min-width: 1024px)",
            {
              labelOffset: 80,
              chartPadding: 20
            }
          ]
        ]
      };
    }
  },
  mounted() {
    this.fetchMostCompareCityData();

    analyticsApis.mostClickCity().then(res => {
      this.mostClickCityItems = res.data
        .map((x, i) => {
          return {
            position: i + 1,
            ...x
          };
        })
        .map(x => {
          return {
            ...x,
            city: this.NameCaps(x.city),
            country: this.NameCaps(x.country)
          };
        });
    });

    analyticsApis.mostSearchedCity().then(res => {
      this.mostSearchCityItems = res.data
        .map((x, i) => {
          return {
            position: i + 1,
            ...x
          };
        })
        .map(x => {
          return {
            ...x,
            city: this.NameCaps(x.city),
            country: this.NameCaps(x.country)
          };
        });
    });
    analyticsApis.GetMostClickedCountries().then(res => {
      this.mostClickCountryItems = res.data
        .map((x, i) => {
          return {
            position: i + 1,
            ...x
          };
        })
        .map(x => {
          return {
            ...x,
            country: this.NameCaps(x.name),
            ctr: x.clickCount ? x.clickCount : 0,
          };
        });
    });
    this.fetchMostCompareCountryData();
    analyticsApis.MostSearchedCountry().then(res => {
      this.mostSearchCountryItems = res.data
        .map((x, i) => {
          return {
            position: i + 1,
            ...x
          };
        })
        .map(x => {
          return {
            ...x,
            country: this.NameCaps(x.name)
          };
        });
    });
   
    analyticsApis.getCount().then(res => {
      this.organiserCount = res.data.organiserCount;
      this.venueCount = res.data.venueCount;
      this.citySubscriberCount = res.data.citySubscriberCount;
      this.cityCount = res.data.cityCount;
      this.advancedCitySubscriberCount = res.data.advancedCitySubscriberCount;
      this.basicCitySubscriberCount = res.data.basicCitySubscriberCount;
    });
    analyticsApis.getRFPCount().then(res => {
      this.completedRFPCount = res.data.completedRFPCount;
      this.openRFPCount = res.data.openRFPCount;
      this.total = res.data.total;
    });
  },
  methods: {
    handleDateChange() {
      this.startDateMenu = false;
      this.endDateMenu = false;
    },
        fetchMostCompareCityData() {
            const requestData = {     
          start_date: this.startDate ? this.formatDate(this.startDate) : undefined,
          end_date: this.endDate ? this.formatDate(this.endDate) : undefined
        };

        analyticsApis.MostCompareCity({params: requestData}).then(res => {
            this.processMostCompareCityData(res.data);
        });

        analyticsApis.MostCompareCountry({params: requestData}).then(res => {
            this.processMostCompareCountryData(res.data);
        });

        analyticsApis.getRFPCount({params: requestData}).then(res => {
            this.completedRFPCount = res.data.completedRFPCount;
            this.openRFPCount = res.data.openRFPCount;
            this.total = res.data.total;
        });

        analyticsApis.getCount({params: requestData}).then(res => {
            this.organiserCount = res.data.organiserCount;
            this.venueCount = res.data.venueCount;
            this.citySubscriberCount = res.data.citySubscriberCount;
            this.cityCount = res.data.cityCount;
            this.advancedCitySubscriberCount = res.data.advancedCitySubscriberCount;
            this.basicCitySubscriberCount = res.data.basicCitySubscriberCount;
            // calculate the percentage of advanced city subscribers to total city subscribers
    });
    },
    fetchMostCompareCountryData() {
      const requestData = {     
          start_date: this.startDate ? this.formatDate(this.startDate) : undefined,
          end_date: this.endDate ? this.formatDate(this.endDate) : undefined
        };

        analyticsApis.MostCompareCountry({params: requestData}).then(res => {
            this.processMostCompareCountryData(res.data);
        });
    },

    processMostCompareCityData(data) {
        this.mostCompareCityItems = data
            .map((x, i) => {
                return {
                    position: i + 1,
                    ...x
                };
            })
            .map(x => {
                return {
                    ...x,
                    cityInfo: {
                        ...x.cityInfo,
                        city: this.NameCaps(x.cityInfo.city),
                        country: this.NameCaps(x.cityInfo.country)
                    }
                };
            });
    },

    processMostCompareCountryData(data) {
        
      this.mostCompareCountryItems = data
        .map((x, i) => {
          return {
            position: i + 1,
            ...x
          };
        })
        .map(x => {
          return {
            ...x,
            country: this.NameCaps(x._id),
            ctr: x.count,
          };
        });
      
    },
    

    updateEndDate() {
      // Update the end date if it is before the start date
      if (this.endDate && this.startDate && new Date(this.endDate) < new Date(this.startDate)) {
        this.endDate = null;
      }
    },
    filterData() {
      const params = {
      start_date: this.startDate ? this.formatDate(this.startDate) : undefined,
      end_date: this.endDate ? this.formatDate(this.endDate) : undefined
    };
    console.log("I don't know what the perpose of this filter function. please remove it if not needed")
    console.log(params)
  },
  resetDates() {
  this.startDate = null;
  this.endDate = null; 
  this.fetchMostCompareCityData();
},
  formatDate(date) {
    // Format the date to YYYY-MM-DD format
    if (!(date instanceof Date)) {date = new Date(date);}
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
  },
   
    complete(index) {
      this.list[index] = !this.list[index];
    },
    NameCaps(name) {
      try {
        let res, firstChar, tail;
        name = name.trim().toLowerCase();
        res = name.split(" ");
        for (var i = 0; i < res.length; i++) {
          firstChar = res[i].substring(0, 1);
          firstChar = firstChar.toUpperCase();
          tail = tail = res[i].substring(1);
          res[i] = firstChar + tail;
          if (i === 0) {
            name = res[i];
          } else {
            name = name + " " + res[i];
          }
        }
      } catch (e) {
        console.log('Name Caps Failed!');
      }
      return name;
    },
    async exportMostClickedFn() {
      let j2csvFile = [];
      await analyticsApis.GetMostClickedJSON().then(res => {
        j2csvFile = res.data;
      });
      let exportObj = j2csvFile.map(x => {
        return {
          // ID: x._id,
          City: x.city,
          Country: x.country,
          IsAdvance: x.isAdvance ? "Yes" : "No",
          Address: x.formattedAddress?.replace(/,/g, ""),
          ClickCount: x.clickCount
          // GooglePlaceID: x.cityInfo.googlePlaceId,
          // Images : x.cityInfo.img
        };
      });
      var headers = {
        // ID: "ID",
        City: "City",
        Country: "Country",
        IsAdvance: "Is Advance Member",
        Address: "Address",
        ClickCount: "Click Count"
        // GooglePlaceID: "GooglePlaceID",
        // Images : "Images"
      };
      var fileTitle = "Most Clicked Cities"; // or 'my-unique-title'

      this.exportCSVFile(headers, exportObj, fileTitle);
    },
    async exportMostClickedCountryFn() {
      let j2csvFile = [];
      await analyticsApis.GetMostClickedCountryJSON().then(res => {
        j2csvFile = res.data;
      });
      let exportObj = j2csvFile.map(x => {
        return {
          Country: x.name,
          IsAdvance: x.isAdvance ? "Yes" : "No",
          Claimed: x.claimed ? "Yes" : "No",
          ClickCount: x.clickCount ? x.clickCount : 0
        };
      });
      var headers = {
        Country: "Country",
        IsAdvance: "Is Advance Member",
        Claimed: "Claimed",
        ClickCount: "Click Count"
      };
      var fileTitle = "Most Clicked Countries"; // or 'my-unique-title'
      this.exportCSVFile(headers, exportObj, fileTitle);
    },
    async exportMostSearchedFn() {
      let j2csvFile = [];
      await analyticsApis.GetMostSearchedCities().then(res => {
        j2csvFile = res.data;
      });
      console.log(j2csvFile)
      let exportObj = j2csvFile.map(x => {
        return {
          // ID: x._id,
          City: x.city,
          Country: x.country,
          IsAdvance: x.isAdvance ? "Yes" : "No",
          Address: x.formattedAddress?.replace(/,/g, ""),
          ClickCount: x.searchCount
          // GooglePlaceID: x.cityInfo.googlePlaceId,
          // Images : x.cityInfo.img
        };
      });
      var headers = {
        // ID: "ID",
        City: "City",
        Country: "Country",
        IsAdvance: "Is Advance Member",
        Address: "Address",
        ClickCount: "Search Count"
        // GooglePlaceID: "GooglePlaceID",
        // Images : "Images"
      };
      var fileTitle = "Most Searched Cities"; // or 'my-unique-title'

      this.exportCSVFile(headers, exportObj, fileTitle);
    },
    async exportMostSearchedCountryFn() {
      let j2csvFile = [];
      await analyticsApis.GetMostSearchedCountries().then(res => {
        j2csvFile = res.data;
      });

      let exportObj = j2csvFile.map(x => {
        return {
          Country: x.name,
          IsAdvance: x.isAdvance ? "Yes" : "No",
          Claimed: x.claimed ? "Yes" : "No",
          SearchCount: x.searchCount ? x.searchCount : 0
        };
      });
      var headers = {
        Country: "Country",
        IsAdvance: "Is Advance Member",
        Claimed: "Is Claimed",
        SearchCount: "Search Count"
      };
      var fileTitle = "Most Searched Countries"; // or 'my-unique-title'

      this.exportCSVFile(headers, exportObj, fileTitle);
    },
    async exportMostComparedFn() {
      let j2csvFile = [];
      await analyticsApis.GetMostComparedJSON().then(res => {
        j2csvFile = res.data;
      });
      let exportObj = j2csvFile.map(x => {
        return {
          // ID: x._id,
          City: x.cityInfo?.city,
          Country: x.cityInfo?.country,
          Continent: x.cityInfo?.continent,
          Address: x.cityInfo?.formattedAddress?.replace(/,/g, ""),
          Total: x.total
          // GooglePlaceID: x.cityInfo.googlePlaceId,
          // Images : x.cityInfo.img
        };
      });
      var headers = {
        // ID: "ID",
        City: "City",
        Country: "Country",
        Continent: "Continent",
        Address: "Address",
        Total: "Total"
        // GooglePlaceID: "GooglePlaceID",
        // Images : "Images"
      };
      var fileTitle = "Most Compared Cities"; // or 'my-unique-title'

      this.exportCSVFile(headers, exportObj, fileTitle);
    },
    async exportMostComparedCountryFn() {
      let j2csvFile = [];
      await analyticsApis.GetMostComparedCountryJSON().then(res => {
        j2csvFile = res.data;
      });
      let exportObj = j2csvFile.map(x => {
        return {
          Country: x._id,
          Total: x.count ? x.count : 0,
        };
      });
      var headers = {
        Country: "Country",
        Total: "Total",
      };
      var fileTitle = "Most Compared Countries"; // or 'my-unique-title'

      this.exportCSVFile(headers, exportObj, fileTitle);
    },

    convertToCSV(objArray) {
      var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
      var str = "";

      for (var i = 0; i < array.length; i++) {
        var line = "";
        for (var index in array[i]) {
          if (line != "") line += ",";

          line += array[i][index];
        }

        str += line + "\r\n";
      }

      return str;
    },
    exportCSVFile(headers, items, fileTitle) {
      if (headers) {
        items.unshift(headers);
      }

      // Convert Object to JSON
      var jsonObject = JSON.stringify(items);

      var csv = this.convertToCSV(jsonObject);

      var exportedFilenmae = fileTitle + ".csv" || "export.csv";

      var blob = new Blob([csv], {type: "text/csv;charset=utf-8;"});
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
      } else {
        var link = document.createElement("a");
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", exportedFilenmae);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    }
  }
};
</script>
<style scoped>
.custom-title-style {
    font-size: 15px; /* Adjust the pixel size as needed */
}
.datepicker {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
</style>